export default {
  items: [
    {
      name: 'Atradnes',
      url: '/atradnes',
      icon: 'fa fa-industry'
    },
    {
      name: 'Izpētes/uzziņas',
      url: '/izpetes',
      icon: 'fa fa-lightbulb-o'
    },
    {
      name: 'Dokumentālās pases',
      url: '/pases',
      icon: 'fa fa-book',
    },
    {
      name: 'Limiti',
      url: '/limiti',
      icon: 'fa fa-unlock-alt',
    },
    {
      name: 'Ieguves licences',
      url: '/licences',
      icon: 'fa fa-id-card-o',
    },
    {
      name: 'Krājumu bilance',
      url: '/bilance',
      icon: 'fa fa-cubes',
    },
    {
      name: 'Klasifikatori',
      url: '/klasifikatori',
      icon: 'fa fa-sort-amount-asc',
    },
    {
      name: 'Pielikumi',
      url: '/pielikumi',
      icon: 'fa fa-paperclip',
    },
    {
      name: 'Paziņojumi',
      url: '/pazinojumi',
      icon: 'fa fa-bell-o',
      badge: {
        variant: 'info',
        text: 'JAUNI',
      }
    },
    {
      name: 'Administrēšana',
      url: '/admin',
      icon: 'fa fa-cogs',
      children: [
        {
          name: 'Lietotāji',
          url: '/lietotaji',
          icon: 'fa fa-user-o',
        },
        {
          name: 'Audits',
          url: '/audits',
          icon: 'fa fa-clock-o',
        }
      ],
    }
  ],
};
