import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

const Atradnes = Loadable({
  loader: () => import('./views/Atradnes/Saraksts'),
  loading: Loading,
});

const Atradne = Loadable({
  loader: () => import('./views/Atradnes/Atradne'),
  loading: Loading,
});

const AtradnesDala = Loadable({
  loader: () => import('./views/Atradnes/AtradnesDala'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Sākums', component: DefaultLayout },
  { path: '/atradnes', exact: true, name: 'Atradnes', component: Atradnes },
  { path: '/atradnes/:numurs', exact: true , name: 'Atradnes kartiņa', component: Atradne },
  { path: '/atradnes/:numurs/:dalaId', name: 'Atradnes daļa', component: AtradnesDala }
];

export default routes;