import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

class Login extends Component {

 constructor(props) {
    super(props);
    this.state = {
      lietotajs: '',
      parole: ''
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
    console.log(this.state);
  }
  
  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onLogin();
    }
  }

  render() {
    if(this.props.hasLogged === true){
      this.props.history.push('/');
      return '';
    }
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h2>Zemes Dzīļu IS</h2>
                      <p className="text-muted">Ievadiet Aktīvās Direktorijas lietotāju/paroli</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" name="lietotajs" placeholder="Lietotājvārds" onChange={this.handleInputChange} autoComplete="username" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" name="parole" placeholder="Parole" onKeyPress={this.handleKeyPress} onChange={this.handleInputChange} autoComplete="current-password" />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button disabled={!(this.state.lietotajs.length > 0 && this.state.parole.length > 0)} color="primary" className="px-4" onClick={this.props.onLogin}>Ieiet</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
