import React, { Component } from 'react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler, AppBreadcrumb } from '@coreui/react';
import logo from '../../assets/img/brand/LVGMC-logo.svg'

import routes from '../../routes';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand href="/">
          <img src={logo} alt='LVĢMC Logo' height="38"/>
        </AppNavbarBrand>
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <AppBreadcrumb appRoutes={routes}/>
        <Nav className="ml-auto" navbar>
          <a href="/" className="nav-link"><i className="fa fa-question fa-lg"></i></a>
          <AppHeaderDropdown className="padding-10" direction="down">
            <DropdownToggle nav>
            <i className="fa fa-bell-o fa-lg"></i><Badge pill color="danger">5</Badge>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem><i className="fa fa-bell-o"></i> Dokumentu termiņi<Badge color="info">2</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-envelope-o"></i>Paziņojumi<Badge color="success">3</Badge></DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
          <AppHeaderDropdown className="padding-10" direction="down">
            <DropdownToggle caret nav>
              Pēteris Liepiņš
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem><i className="fa fa-wrench"></i> Uzstādījumi</DropdownItem>
              <DropdownItem><i className="fa fa-lock"></i> Iziet</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
