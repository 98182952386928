import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import 'react-tagsinput/react-tagsinput.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '@boundlessgeo/sdk/stylesheet/sdk.scss';
import './App.scss';

// Containers
import { DefaultLayout } from './containers';
// Pages
import Login from './views/Pages/Login';

// import { renderRoutes } from 'react-router-config';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasLogged: false
    };
  }
  
  handleLogin() {
    console.log(this.state);
    this.setState({hasLogged: true});
    console.log(this.state);
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" render={(props) => <Login {...props} hasLogged={this.state.hasLogged} onLogin={() => this.handleLogin()}/>} name="Pieslēgšanās" />
          <Route path="/" name="Sākums" render={
            (props) => (
            <DefaultLayout {...props} hasLogged={this.state.hasLogged}/>
            )}/>
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
